.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #1e2a45;
  margin: 6px 0;
  transition: 0.4s;
}
.change {
  cursor: pointer;
  z-index: 999;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background-color: #1e2a45;
}

.change .bar2 {
  opacity: 0;
  background-color: #1e2a45;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: #1e2a45;
}

@media (min-width: 600px) {
  .container {
    display: none;
  }
  .change {
    display: none
  }
}