.leaflet-container {
  height: 700px;
  width: 60%;
}
.card-img, .card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; 
  border: 1px;
}

@media (max-width: 1000px) {
  .leaflet-container {
    height: 500px;
    width: 100%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1650px) {
  .leaflet-container {
    height: 700px;
    width: 50%;
  }
}